import styled, { css } from 'styled-components';

import { PageHeading } from 'components/Typography';

const Container = styled.main`
  overflow: hidden;
  position: relative;
  width: 100%;
`;

const Content = styled.div(
  ({ theme }) => css`
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: ${theme.spacing.big};
    z-index: 1;

    ${theme.onScreen('SMALL_DESKTOP')} {
      margin: 0 auto;
      padding: 0;
      width: 100%;
    }
  `
);

const Heading = styled(PageHeading)(
  ({ theme }) => css`
    width: 100%;

    ${theme.onScreen('SMALL_DESKTOP')} {
      margin: ${theme.spacing.huge} auto;
      margin-bottom: 0;
      width: 1180px;
    }
  `
);

export const AboutPageElements = {
  Container,
  Content,
  Heading
};
