import React from 'react';
import styled, { css } from 'styled-components';

interface DashSquareProps {
  color?: string;
  size?: number;
}

const DashSquareSVG = styled.svg<DashSquareProps>(
  ({ size }) => css`
    height: ${size}px;
    width: ${size}px;
  `
);

export const DashSquare: React.FC<DashSquareProps> = (props) => {
  const { color, size } = props;
  const strokeHeight = 2;
  const strokeWidth = 10;
  const strokeGap = 10;
  const reducedSize = size - strokeWidth;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <DashSquareSVG {...props} xmlns="http://www.w3.org/2000/svg" viewBox={`0 0  ${size} ${size}`}>
      <g fill="none" stroke={color} strokeWidth={strokeWidth} strokeDasharray={`${strokeHeight} ${strokeGap}`}>
        <rect width={reducedSize} height={reducedSize} rx="40" stroke="none" />
        <rect x="5" y="5" width={reducedSize} height={reducedSize} rx="35" fill="none" />
      </g>
    </DashSquareSVG>
  );
};
