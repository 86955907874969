import React from 'react';
import styled, { css } from 'styled-components';

import { useTheme } from 'lib/hooks';

import { DashSquare } from '../DashSquare';

const Container = styled.div(
  ({ theme }) => css`
    display: none;

    position: absolute;
    transform: rotate(45deg);

    ${theme.onScreen('SMALL_DESKTOP')} {
      display: flex;
      flex-direction: column;
      top: -10vh;
      flex-wrap: wrap;
      width: 1200px;
      height: 1200px;
      right: -10vmax;
      z-index: -1;

      > svg {
        align-self: flex-end;
        margin: ${theme.spacing.mid};

        &:nth-child(3),
        &:nth-child(4) {
          align-self: flex-start;
        }
      }
    }

    ${theme.onScreen('MEDIUM_DESKTOP')} {
      right: -35vmax;
    }

    ${theme.onScreen('DESKTOP')} {
      right: -25vmax;
    }
  `
);

export const SquareBackground: React.FC = () => {
  const theme = useTheme();

  return (
    <Container>
      <DashSquare color={theme.color.gray[200]} size={200} />
      <DashSquare color={theme.color.blue[100]} size={550} />
      <DashSquare color={theme.color.blue[300]} size={450} />
      <DashSquare color={theme.color.gray[200]} size={700} />
    </Container>
  );
};
