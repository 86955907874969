import styled, { css } from 'styled-components';

export const PageHeading = styled.h1<{ variant?: 'sub' | 'main' }>(
  ({ theme, variant }) => css`
    ${theme.font(variant !== 'sub' ? 25 : 18, 'BOLD')};
    color: ${theme.color.blue[200]};

    ${theme.onScreen('TABLET_LANDSCAPE')} {
      ${theme.font(variant !== 'sub' ? 30 : 26, 'BOLD')};
      margin-top: ${variant === 'sub' ? '20px' : 0};
    }

    ${theme.onScreen('SMALL_DESKTOP')} {
      ${theme.font(variant !== 'sub' ? 36 : 28, 'BOLD')};
      margin-top: ${theme.spacing.big};
    }

    ${theme.onScreen('MEDIUM_DESKTOP')} {
      ${theme.font(variant !== 'sub' ? 48 : 32, 'BOLD')};
    }
  `
);

export const HeadingDescription = styled.p(
  ({ theme }) => css`
    color: ${theme.color.gray[200]};
    ${theme.font(16, 'REGULAR')};

    ${theme.onScreen('MEDIUM_DESKTOP')} {
      ${theme.font(20, 'REGULAR')};
    }
  `
);

export const HeadingSubtext = styled.p(
  ({ theme }) => css`
    color: ${theme.color.gray[150]};
    ${theme.font(16, 'REGULAR')};

    ${theme.onScreen('MEDIUM_DESKTOP')} {
      ${theme.font(16, 'REGULAR')};
    }
  `
);
