import styled, { css } from 'styled-components';

const Container = styled.div(
  ({ theme }) => css`
    display: grid;
    grid-gap: ${theme.spacing.huge};
    grid-template-columns: 1fr;
    width: 100%;

    ${theme.onScreen('SMALL_DESKTOP')} {
      margin-top: ${theme.spacing.huge};
      width: 1180px;
    }
  `
);

const Info = styled.article(
  ({ theme }) => css`
    background: ${theme.color.white};
    color: ${theme.color.gray[200]};
    ${theme.font(18)};
    text-align: justify;
    width: 100%;

    > p {
      margin-bottom: ${theme.spacing.big};
    }

    ${theme.onScreen('SMALL_DESKTOP')} {
      ${theme.font(20)}
    }
  `
);

const Img = styled.div(
  ({ theme }) => css`
    display: none;

    ${theme.onScreen('TABLET_LANDSCAPE')} {
      align-items: center;
      background: ${theme.color.white};
      display: flex;
      justify-content: center;
      width: 100%;
    }
  `
);

const Image = styled.img`
  width: 100%;
`;

const RecommendationsContainer = styled.div(
  ({ theme }) => css`
    display: grid;
    grid-gap: ${theme.spacing.big};
    grid-template-columns: minmax(240px, 1fr);

    ${theme.onScreen('TABLET')} {
      grid-template-columns: repeat(2, 180px);
    }

    ${theme.onScreen('TABLET_LANDSCAPE')} {
      grid-template-columns: repeat(4, 1fr);
    }

    ${theme.onScreen('SMALL_DESKTOP')} {
      grid-template-columns: repeat(4, 240px);
    }
  `
);

const KaptialnaFirmaContainer = styled.div(
  ({ theme }) => css`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-block: ${theme.spacing.bigger};
    width: 100%;

    img {
      max-width: 230px;
      width: 100%;
    }
  `
);

export const BriefInfoElements = {
  Container,
  Info,
  Img,
  Image,
  RecommendationsContainer,
  KaptialnaFirmaContainer
};
