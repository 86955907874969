import React from 'react';

import { SquareBackground } from 'components/SquareBackground';
import Layout from 'layouts';
import { SEO } from 'SEO';

import { Additional } from './components/Additional';
import { BriefInfo } from './components/BriefInfo';
import { AboutPageElements } from './elements';

const { Heading, Container, Content } = AboutPageElements;

export const AboutPage: React.FC = () => (
  <Layout>
    <SEO title="O firmie" canonicalHref="/ofirmie/" />
    <Container>
      <Content>
        <Heading>O firmie</Heading>
        <BriefInfo />
        <Additional />
      </Content>
      <SquareBackground />
    </Container>
  </Layout>
);
