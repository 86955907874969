import React from 'react';

import { AdditionalElements } from './elements';

const { Container, Content, Title, P } = AdditionalElements;

export const Additional: React.FC = () => (
  <Container>
    <Content>
      <Title>Kompleksowa i profesjonalna realizacja.</Title>
      <P>
        Firma zatrudnia fachowców w branży technicznej i projektowej. Oferujemy kompleksowe oraz precyzyjne wykonanie
        projektu w programie wektorowym Corel.
      </P>
    </Content>

    <Content>
      <Title>Szerokie grono klientów z całego świata.</Title>
      <P>
        Współpracujemy z takimi firmami jak: Haco-Fat, Wabco Polska, Volvo Polska, Deleval. Produkujemy również
        tabliczki do krajów Unii Europejskiej.
      </P>
    </Content>
    <Content>
      <Title>Indywidualne podejście do zamówień.</Title>
      <P>
        Oferujemy krótkie terminy wykonania, nasz standard to 5 - 7 dni. W pilnych sytuacjach zamówienie wykonujemy
        nawet w 48 godzin. Termin płatności ustalamy indywidualnie z klientem.
      </P>
    </Content>
    <Content>
      <Title>Jesteśmy otwarci na zagraniczne rynki.</Title>
      <P>Czytamy z dokumentacji technicznej w języku angielskim. Współpracujemy z klientami z poza rynku polskiego.</P>
    </Content>
  </Container>
);
