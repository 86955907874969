import styled, { css } from 'styled-components';

const Container = styled.section(
  ({ theme }) => css`
    display: grid;
    grid-gap: ${theme.spacing.huge};
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    margin-top: ${theme.spacing.big};
    width: 100%;

    ${theme.onScreen('TABLET_LANDSCAPE')} {
      margin-top: 80px;
    }

    ${theme.onScreen('SMALL_DESKTOP')} {
      width: 1180px;
    }

    ${theme.onScreen('MEDIUM_DESKTOP')} {
      margin-top: 160px;
    }
  `
);

const Content = styled.div(
  ({ theme }) => css`
    background: ${theme.color.white};
    padding: ${theme.spacing.big};
  `
);

const Title = styled.h2(
  ({ theme }) => css`
    color: ${theme.color.blue[200]};
    ${theme.font(20, 'BOLD')};
    margin-bottom: ${theme.spacing.big};
    position: relative;

    &:before {
      background: ${theme.color.blue[400]};
      content: '';
      height: 3px;
      position: absolute;
      top: -10px;
      width: 20px;
    }
  `
);

const P = styled.p(
  ({ theme }) => css`
    color: ${theme.color.gray[200]};
    line-height: 1.6;
    margin-bottom: ${theme.spacing.mid};
    text-align: justify;
  `
);

export const AdditionalElements = {
  Container,
  Content,
  P,
  Title
};
