import React from 'react';

import { PDFView } from 'components/PDFView';
import main from 'images/about/main.jpg';

import { BriefInfoElements } from './elements';
import kapitalnaIMG from './kapitalna_firma.jpg';
import atexPDF from './recomm/atex.pdf';
import indivizoPDF from './recomm/indivizo.pdf';
import kapitalnaPDF from './recomm/kapitalna_firma.pdf';
import proteaPDF from './recomm/protea.pdf';

const { Container, Info, Img, Image, RecommendationsContainer, KaptialnaFirmaContainer } = BriefInfoElements;

export const BriefInfo: React.FC = () => (
  <Container>
    <Info>
      <p>
        Nasza firma znajduje się na rynku już od 2003 roku. Przez lata zdobywaliśmy zaufanie klientów wytwarzając
        wysokiej jakości produkty. Obecnie oferujemy nasze usługi firmom z całej Europy.
      </p>
      <p>Przyjmujemy zlecenia na wszelkiego rodzaju usługi zwiążane z obróbką metalu i tworzyw sztucznych.</p>
      <p>Zapraszamy do zapoznania się z naszą ofertą.</p>
      <p>
        W związku z rozwojem działalności w 2022 r. rozpoczęła działalność nowa firma Bachem Sp. z o.o, która działa
        równolegle z firmą Bachem Tabliczki znamionowe. Jest to w dalszym ciągu firma z grupą ekspertów, dbających o jak
        najlepszą współpracę. Misją naszych firm jest świadczenie wysokiej jakości usług oraz zadowolenie kontrahentów
        ze współpracy z firmą. Równie ważne jest dla nas zapewnienie dobrych warunków pracy i możliwości rozwoju swoim
        pracownikom.
      </p>
      <p>
        Chcemy zostać liderami w branży i wyznaczać kierunki dla sektora technologii wykorzystywanych w obróbce metali i
        tworzyw sztucznych. Dążąc do ulepszeń i stałego rozwoju koncertujemy swój rozwój wokół nowych rozwiązań
        technologicznych, doskonaląc przy tym samym dotychczasowe produkty.{' '}
      </p>
    </Info>
    <Img>
      <Image src={main} />
    </Img>
    <RecommendationsContainer>
      <PDFView doc={indivizoPDF} docName="Referencje INDIVIZO" />
      <PDFView doc={atexPDF} docName="Referencje ATEX" />
      <PDFView doc={proteaPDF} docName="Referencje PROTEA" />
      <PDFView doc={kapitalnaPDF} docName="Certyfikat Kapitalna Firma" />
    </RecommendationsContainer>
    <KaptialnaFirmaContainer>
      <img src={kapitalnaIMG} alt="kapitalna firma" />
    </KaptialnaFirmaContainer>
  </Container>
);
