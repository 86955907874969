import React from 'react';

import { PDFViewElements } from './elements';

const { Miniature, PDFIcon } = PDFViewElements;

interface PDFViewProps {
  doc: string;
  docName: string;
}

export const PDFView: React.FC<PDFViewProps> = ({ doc, docName }) => (
  <>
    <a href={doc} target="_blank" rel="noreferrer">
      <Miniature>
        <PDFIcon>PDF</PDFIcon>
        {docName}
      </Miniature>
    </a>
  </>
);
