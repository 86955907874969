import styled, { css } from 'styled-components';

import { Paper } from '../Paper';

const Miniature = styled(Paper)`
  cursor: pointer;
  display: flex;
  flex-direction: column;
`;

const PDFIcon = styled.div(
  ({ theme }) => css`
    ${theme.font(13, 'BOLD')};
    background: #d04044;
    border-radius: 2px;
    color: ${theme.color.white};
    line-height: 1;
    margin-bottom: ${theme.spacing.small};
    padding: 4px;
    width: fit-content;
  `
);

export const PDFViewElements = {
  Miniature,
  PDFIcon
};
